import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../ui/button";

import Logo from "../../assets/images/Logo.png";
import LogoSecondary from "../../assets/images/Logo_secondary.png";
import Flag_GB from "../../assets/images/flag_gb.svg";
import Flag_DE from "../../assets/images/flag_DE.svg";
import { useLanguage } from "../../context/LanguageContext";

export default function Header({ isPrimary = true }) {
  const { t, ready } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const [activeNav, setActiveNav] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const flags = {
    EN: Flag_GB,
    DE: Flag_DE,
  };

  const toggleNavbar = () => {
    setActiveNav((prev) => !prev);
  };

  const handleSetLanguage = (e, lang) => {
    e.preventDefault();
    if (lang === language) return;
    setLanguage(lang);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  if (!ready) return null;

  return (
    <header className="fixed left-0 top-0 w-full z-30">
      <nav className={`relative z-30 w-full `}>
        <section
          className={`mx-auto flex h-14 w-full items-center justify-between px-5 md:mx-auto md:h-20 md:px-10  lg:h-[108px] transition-all duration-300 ${
            scrolling ? "drop-shadow-md bg-white lg:h-[90px]" : ""
          }`}
        >
          <div className="flex items-center gap-1 text-center md:gap-8 lg:gap-12">
            <a href="/">
              {isPrimary || scrolling ? (
                <img
                  alt="Wandel Logo"
                  decoding="async"
                  data-nimg="1"
                  className="w-[100px] cursor-pointer md:w-[134px]"
                  src={Logo}
                />
              ) : (
                <img
                  alt="Wandel Logo"
                  decoding="async"
                  data-nimg="1"
                  className="w-[100px] cursor-pointer md:w-[134px]"
                  src={LogoSecondary}
                />
              )}
            </a>
            <div className="hidden md:flex">
              <ul className="flex gap-8 sm:gap-2 md:gap-4">
                <li>
                  <a href="/about-us">
                    <span
                      className={`text-[12px] font-[400] ${
                        isPrimary || scrolling
                          ? "text-black"
                          : "text-white hover:!text-white"
                      } hover:text-primary-100 md:text-[14px] lg:text-[16px]`}
                    >
                      {t("about_us")}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.wandel.com/positions">
                    <span
                      className={`text-[12px] font-[400] ${
                        isPrimary || scrolling
                          ? "text-black"
                          : "text-white hover:!text-white"
                      } hover:text-primary-100 md:text-[14px] lg:text-[16px]`}
                    >
                      {t("jobs_Directory")}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex h-10 items-center gap-1 md:gap-5">
            <div className="dropdown mr-5">
              <button
                className="flex cursor-pointer items-center gap-2"
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                type="button"
              >
                <span
                  className={`hidden text-xs font-normal ${
                    isPrimary || scrolling ? "text-black" : "text-white"
                  } md:text-sm lg:block`}
                >
                  {language}
                </span>
                <img src={flags[language]} alt="en" className="h-5 w-7" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className={`${
                    isPrimary || scrolling ? "text-black" : "text-white"
                  }`}
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>

              <div id="dropdown" className="hidden">
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200 bg-white rounded-md p-2 flex flex-col shadow-lg"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li className={`p-2 ${language === "EN" ? "selected" : ""}`}>
                    <a href="#" onClick={(e) => handleSetLanguage(e, "EN")}>
                      <span className="flex items-center gap-2 text-black hover:text-primary-100">
                        <span className="hidden text-xs font-normal md:text-sm lg:block">
                          EN
                        </span>
                        <img src={Flag_GB} alt="en" className="h-5 w-7" />
                      </span>
                    </a>
                  </li>
                  <li className={`p-2 ${language === "DE" ? "selected" : ""}`}>
                    <a href="#" onClick={(e) => handleSetLanguage(e, "DE")}>
                      <span className="flex items-center gap-2 text-black hover:text-primary-100">
                        <span className="hidden text-xs font-normal md:text-sm lg:block">
                          DE
                        </span>
                        <img src={Flag_DE} alt="de" className="h-5 w-7" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <button
              className={`hamburger-button cursor-pointer text-[25px] md:hidden ${
                activeNav ? "open" : ""
              }`}
              onClick={toggleNavbar}
            >
              <span className="icon"></span>
            </button>
            {
              /*
              <Button
                variant="outline"
                className={`hidden h-12 w-32 md:block ${
                  isPrimary || scrolling
                    ? ""
                    : "!bg-transparent text-white border-white"
                }`}
              >
                {t("log_in")}
              </Button>
              */
            }
            <a href="/onboarding">
              <Button variant="primary" className="hidden h-12 w-32 md:block">
                {t("register")}
              </Button>
            </a>
          </div>
        </section>
        <div className={`sidebar ${activeNav ? "open" : ""}`}>
          <div className="mt-2 flex w-full items-center justify-end">
            <button
              className="cursor-pointer"
              onClick={() => setActiveNav(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <ul className="mt-4 flex flex-col gap-4">
            <li>
              <a href="/about-us">
                <span className="text-[16px] font-[400] text-[#7B8495] hover:text-primary-100">
                  {t("about_us")}
                </span>
              </a>
            </li>
            <li>
              <a href="/jobs">
                <span className="text-[16px] font-[400] text-[#7B8495] hover:text-primary-100">
                  {t("jobs_Directory")}
                </span>
              </a>
            </li>
            <li>
              <div className="flex md:items-center flex-col md:flex-row gap-4">
                {/*
                <Button variant="outline" className="h-12 w-32">
                  {t("log_in")}
                </Button>
                */}
                <a href="/onboarding">
                  <Button variant="primary" className="h-12 w-32">
                    {t("register")}
                  </Button>
                </a>
              </div>
            </li>
            <li></li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
