import React, { createContext, useContext, useState } from "react";

export const ONBOARDING_STEP_MAIN_PAGE = "main_page";
export const ONBOARDING_STEP_LOCATION = "location";
export const ONBOARDING_STEP_EXPERIENCE = "experience";
export const ONBOARDING_STEP_EDUCATION = "education";
export const ONBOARDING_STEP_CONTACT_DATA = "contact_data";
export const ONBOARDING_STEP_CV = "cv";
export const ONBOARDING_EVENT_TYPE_OUT = "out";
export const ONBOARDING_EVENT_TYPE_IN = "in";

// Create a context with an empty default value. We'll never actually use this default value directly,
// because we'll always provide a value using the context provider below.
const StepContext = createContext({
  step: 0, // Default value
  setStep: () => {}, // Placeholder function
});

// Component to provide the context value
export const StepProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  return (
    <StepContext.Provider value={{ step, setStep }}>
      {children}
    </StepContext.Provider>
  );
};

// Custom hook to use the context
export const useStep = () => useContext(StepContext);
