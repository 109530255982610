import React from "react";
import { useForm } from "react-hook-form";
import { useUserIdentifier } from "../../../context/userIdentifierContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import Label from "../../../components/ui/label";
// import Button from "../../../components/ui/button";
import { useStep } from "../../../context/onboardingStepContext";

import {
  ONBOARDING_STEP_EDUCATION,
  ONBOARDING_EVENT_TYPE_OUT,
} from "../../../context/onboardingStepContext";

export default function IndustryInfo() {
  const onboardingInfo = JSON.parse(localStorage.getItem("onboardingInfo"));
  const { setStep } = useStep();
  const { userIdentifier } = useUserIdentifier();
  const { t } = useTranslation("onboarding");

  const form = useForm({
    defaultValues: {
      industry: "",
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  console.log("userIdentifier", userIdentifier);

  const onSubmit = (data) => {
    localStorage.setItem("onboardingInfo", JSON.stringify({
      ...onboardingInfo,
      industry: data.industry
    }))

    /*axios.post("/api/v1/notifications", {
      message: `User \`${Cookies.get(
        "userIdentifier"
      )}\` submitted information on \`Industry\`.`,
      info: data,
    });*/

    axios.post("/api/v1/notifications", {
      info: {
        id: Cookies.get('userIdentifier'),
        industry: data.industry,
        step: ONBOARDING_STEP_EDUCATION,
        event: ONBOARDING_EVENT_TYPE_OUT,
      },
    });

    setStep(2);
  };

  const handleRadioChange = (e) => {
    form.setValue("industry", e.target.value);
    onSubmit({ industry: e.target.value });
  };

  return (
    <div className="mt-10 max-w-[460px]">
      <Label labelText={t("industryLabel")} />
      <h3 className="text-[30px] leading-[36px] font-medium mt-10 max-w-[380px]">
        {t("occupationPrompt")}
      </h3>

      <form
        className="mt-10 grid grid-cols-1 gap-7"
        onSubmit={handleSubmit(onSubmit)}
      >
        {[
          {
            value: "Refrigeration Technology",
            label: t("refrigeration_Technology"),
          },
          {
            value: "System Mechanics for HVAC and Sanitation",
            label: t("system_Mechanics_for_HVAC_and_Sanitation"),
          },
          { value: "Mechatronics", label: t("mechatronics") },
          { value: "Electronics", label: t("electronics") },
          { value: "Industrial Mechanic", label: t("logistics") },
          // { value: "machinist", label: t("machinist") },
          { value: "Other", label: t("other") },
        ].map((option) => (
          <div key={option.value}>
            <label htmlFor={option.value}>
              <input
                type="radio"
                value={option.value}
                id={option.value}
                className="form-industry-radio"
                {...register("industry")}
                onChange={handleRadioChange}
              />
              <span className="cursor-pointer w-full block text-center md:w-auto md:inline md:text-left">
                {option.label}
              </span>
            </label>
          </div>
        ))}

        {/* <div>
          <Button
            variant="primary"
            className={"w-full"}
            type={"submit"}
            disabled={!isDirty || !isValid}
          >
            {t("continueButton")}
          </Button>
        </div> */}
      </form>
    </div>
  );
}
